export const environment = {
  production: true,
  version: 'b65e99bc417faa4a90dd0f47b4cfcc62f48cca04',
  googleMapsApiKey: 'AIzaSyAiv8XnKboepzWpLJW_dB6H4glm8WFDzTE',
  weatherApiKey: 'ecd5bb0efd934aea9a5122330232811',
  weatherApiUrl: 'https://api.weatherapi.com/v1',
  apiBaseUrl: '/api',
  apiPublicUrl: '/api/public',
  apiBaseAdminUrl: '/api/admin',
  apiAirQualityUrl: 'https://airquality.googleapis.com/v1',
};

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import {
  askForChallengeSuccess,
  loadUserAuthenticated,
  loadUserAuthenticatedError,
  loadUserAuthenticatedSuccess,
  postForgotPassword,
  postForgotPasswordError,
  postForgotPasswordSuccess,
  postLogin,
  postLoginError,
  postLoginMfa,
  postLoginMfaError,
  postLoginMfaSuccess,
  postLoginSuccess,
} from '../actions/authentication.action';
import { AuthenticationService } from 'brain-data/service/auth/authentication.service';
import { Router } from '@angular/router';
import { getDashboardlUrl, getMfaUrl } from 'shared/services/utils/url/url.service';
import { loadFacilitiesAtStartup } from '../actions/facility.actions';
import { CreateTokenOutcome, TokenDTO } from '@brain-auth/models/auth-token-dto.model';

@Injectable()
export class AuthenticationEffects {
  postLoginEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postLogin),
      switchMap(({ username, password }) =>
        this.authenticationService.login(username, password).pipe(
          switchMap(loginResponse =>
            this.authenticationService.saveStorageAndLoadUserProfile(loginResponse).pipe(
              map(() => postLoginSuccess()),
              tap(() => void this.router.navigate([getDashboardlUrl()])),
              catchError(error => of(postLoginError({ error }))),
            ),
          ),
          catchError(error => {
            const tokenDto = error.error as TokenDTO;

            if (tokenDto.outcome === CreateTokenOutcome.MFA_REQUIRED) {
              return of(askForChallengeSuccess({ tokenDto })).pipe(
                tap(() => void this.router.navigate([getMfaUrl()])),
                catchError(err => of(postLoginError({ error: err }))),
              );
            }

            return of(postLoginError({ error }));
          }),
        ),
      ),
    );
  });

  postLoginMfaEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postLoginMfa),
      switchMap(({ tokenDto }) =>
        this.authenticationService.loginMfa(tokenDto).pipe(
          switchMap(loginResponse =>
            this.authenticationService.saveStorageAndLoadUserProfile(loginResponse).pipe(
              map(userAuthenticated => postLoginMfaSuccess({ userAuthenticated: (userAuthenticated as any).info })),
              map(() => loadFacilitiesAtStartup()),
              tap(() => void this.router.navigate([getDashboardlUrl()])),
            ),
          ),
          catchError(error => of(postLoginMfaError({ error }))),
        ),
      ),
    );
  });

  loadUserAuthenticatedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUserAuthenticated),
      map(() => {
        const userAuthenticated = this.authenticationService.userAuthenticated();
        if (userAuthenticated) {
          return loadUserAuthenticatedSuccess({ userAuthenticated });
        }
        return loadUserAuthenticatedError({ error: new Error('no user') });
      }),
    );
  });

  postForgotPasswordEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postForgotPassword),
      switchMap(({ email }) => {
        return this.authenticationService.askForPasswordChange(email).pipe(
          map(() => postForgotPasswordSuccess()),
          catchError(error => of(postForgotPasswordError({ error }))),
        );
      }),
    );
  });

  constructor(
    private router: Router,
    private actions$: Actions,
    private authenticationService: AuthenticationService,
  ) {}
}
